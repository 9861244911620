import React, { useState, useEffect, useContext } from 'react';
import Button from './Button';
import Svg from './Svg';
import Headshot from './Headshot';
import Video from './Video';
import { ModalContext } from './ModalContext';
import backgroundImage from '../images/culture_11.webp';
import Chip from './Chip';

export default function Homepage() {
  /**
  *  @desc Returns all png paths in the images directory.
  * @param {function} r webpackContext(req) will return all ending with .png in headshots directory. 
  * @returns {array} png paths in headshots directory.
  */
  function importAll(r) {
    let imgs = {};
    r.keys().map(item => imgs[item.replace('./', '')] = r(item));
    return imgs;
  }

  const imgs = importAll(require.context('../images/projects', false, /\.png/));
  const projectsArr = [
    {
      id: 1,
      imgPath: imgs['resized_0.png'],
      title: 'Chaa Creek Resort Website Redesign',
      text: 'We partnered with Chaa Creek, a luxury resort nestled in the heart of Belize, to rejuvenate their online presence. The project involved a comprehensive redesign, leveraging the latest features of Drupal, to create a site that not only reflected the unique charm of the resort but also catered to their target market. Our efforts resulted in a successful rebrand, driving increased traffic and engagement, and offering a seamless user experience across all devices.'
    },
    {
      id: 2,
      imgPath: imgs['resized_1.png'],
      title: 'Chaa Creek Resort Website Redesign',
      text: 'We partnered with Chaa Creek, a luxury resort nestled in the heart of Belize, to rejuvenate their online presence. The project involved a comprehensive redesign, leveraging the latest features of Drupal, to create a site that not only reflected the unique charm of the resort but also catered to their target market. Our efforts resulted in a successful rebrand, driving increased traffic and engagement, and offering a seamless user experience across all devices.'
    },
    {
      id: 3,
      imgPath: imgs['resized_2.png'],
      title: 'KT-Studios Website Revamp',
      text: 'We had the pleasure of working with KT-Studios to completely transform their website. We crafted a user-friendly, mobile-first experience, bringing their vision to life with a sleek design and custom features like a Netflix-inspired video carousel. Our focus was on creating a polished, professional site that truly showcases the dynamic work of KT-Studios, making sure it shines on any device.'
    },
    {
      id: 4,
      imgPath: imgs['resized_3.png'],
      title: 'KT-Studios Website Revamp',
      text: 'We had the pleasure of working with KT-Studios to completely transform their website. We crafted a user-friendly, mobile-first experience, bringing their vision to life with a sleek design and custom features like a Netflix-inspired video carousel. Our focus was on creating a polished, professional site that truly showcases the dynamic work of KT-Studios, making sure it shines on any device.'
    },
    {
      id: 5,
      imgPath: imgs['resized_4.png'],
      title: 'KT-Studios Website Revamp',
      text: 'We had the pleasure of working with KT-Studios to completely transform their website. We crafted a user-friendly, mobile-first experience, bringing their vision to life with a sleek design and custom features like a Netflix-inspired video carousel. Our focus was on creating a polished, professional site that truly showcases the dynamic work of KT-Studios, making sure it shines on any device.'
    }
  ];

  const [projects, setProjects] = useState(projectsArr);

  const rotateProjects = () => {
    setProjects(prevProjects => {
      let newProjects = [...prevProjects];
      let lastElement = newProjects.pop();
      newProjects.unshift(lastElement);
      return newProjects;
    });
  }

  const rotateProjectsBack = () => {
    setProjects(prevProjects => {
      let newProjects = [...prevProjects];
      let firstElement = newProjects.shift();
      newProjects.push(firstElement);
      return newProjects;
    });
  }

  useEffect(() => {
    document.title = 'TanDev';

    // const intervalId = setInterval(() => {
    //   setProjects(prevProjects => {
    //     let newProjects = [...prevProjects];
    //     let lastElement = newProjects.pop();
    //     newProjects.unshift(lastElement);
    //     return newProjects;
    //   });

    //   return () => {
    //     clearInterval(intervalId);
    //   }
    // }, 5000);
  }, []);

  const { setOpen } = useContext(ModalContext);

  return (
    <div className='homepage'>
      <div className='container'>
        <div className='homepage-title'>
          <img className='background' src={backgroundImage} alt="TanDev team working together." />
          <div className='homepage-title-overlay'></div>
          <div className='homepage-title-container'>
            <div className='homepage-title-content'>
              <div className='chip'>
                <Svg name="handshake" width="15px" height="15px" margin="6px 0px" />
                <p>US-Based. Remote since inception. <span>Drupal Experts.</span></p>
              </div>
              <h2 className='my-5'>Build and scale your business<br />fast with our <span className='gradient-text'>world-class</span><br /><span className='gradient-text-bottom'>senior developers</span></h2>
              <Button text="Let's chat" callBack={() => setOpen(true)} style="1" width="fit-content" arrow={true} />
            </div>
            <div className='homepage-title-grid'>
              <div className='block'>
                <Svg name="drupal_logo" width="56px" height="56px" margin="10px" />
                <div className='block-text'>
                  <div className='heading'>Drupal Development</div>
                  <div className='text mt-2'>Enhance your team and challenge the deadline</div>
                </div>
              </div>
              <div className='block'>
                <Svg name="ic-service-ai" width="56px" height="56px" margin="10px" />
                <div className='block-text'>
                  <div className='heading'>Drupal Migrations, Upgrades</div>
                  <div className='text mt-2'>20+ Migrations Completed</div>
                </div>
              </div>
              <div className='block'>
                <Svg name="ic-service-custom" width="56px" height="56px" margin="10px" />
                <div className='block-text'>
                  <div className='heading'>Custom Software Development</div>
                  <div className='text mt-2'>Custom Software Development and Design</div>
                </div>
              </div>
              <div className='block'>
                <Svg name="wordpress_logo" width="56px" height="56px" margin="10px" />
                <div className='block-text'>
                  <div className='heading'>Wordpress Development</div>
                  <div className='text mt-2'>Develop impactful web apps</div>
                </div>
              </div>
              <div className='block'>
                <Svg name="ic-service-web" width="56px" height="56px" margin="10px" />
                <div className='block-text'>
                  <div className='heading'>Web Development</div>
                  <div className='text mt-2'>Full-Stack Web Development Front end Back End</div>
                </div>
              </div>
              <div className='block'>
                <Svg name="ic-service-product" width="56px" height="56px" margin="10px" />
                <div className='block-text'>
                  <div className='heading'>Pod-Based Teams</div>
                  <div className='text mt-2'>Hire a whole team, at a fraction of the cost.</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='homepage-tech-stack py-5'>
          <div className='heading'>
            <h1 className='homepage-indent'>Tech Stack | Proficiencies</h1>
            <p className='text homepage-indent'>Tap into our end-to-end tech services</p>
          </div>
          <div className='tech-stack-container mt-5'>
            <div className='tech-stack-card'>
              <div className='card-heading mb-3'>Cloud</div>
              <div className='card-text'>Whether you're looking to migrate your Drupal site to the cloud (we have several acquia certified developers on our team), optimize your existing cloud architecture, or develop cloud-native Drupal applications, our expert team is here to help you harness the full potential of cloud technology.</div>
              <div className='card-cta'><Button text="Connect for more" style={6} width='fit-content' type="button" /></div>
            </div>
            <div className='tech-stack-card'>
              <div className='card-heading mb-3'>Digital Transformation</div>
              <div className='card-text'>We reshape business operations, making digital room for more connectivity, security, and exponential growth.</div>
              <div className='card-cta'><Button text="Connect for more" style={6} width='fit-content' type="button" /></div>
            </div>
            <div className='tech-stack-card'>
              <div className='card-heading mb-3'>Security</div>
              <div className='card-text'>Threat assessment, network security, data encryption, and incident response planning specifically for Drupal environments. Trust our experienced cybersecurity professionals to ensure your Drupal site remains secure and compliant with industry standards.</div>
              <div className='card-cta'><Button text="Connect for more" style={6} width='fit-content' type="button" /></div>
            </div>
            <div className='tech-stack-card'>
              <div className='card-heading mb-3'>DevOps</div>
              <div className='card-text'>From continuous integration and continuous deployment (CI/CD) to automation and infrastructure as code (IaC), our DevOps services are tailored to meet the unique needs of your Drupal development.</div>
              <div className='card-cta'><Button text="Connect for more" style={6} width='fit-content' type="button" /></div>
            </div>
          </div>
        </div>
        <div className='homepage-our-model py-5 homepage-indent'>
          <div className='mb-4 above-heading'>Our Model</div>
          <div className='our-model-container mb-5'>
            <div className='heading col-md-4'>
              <h3>What Makes Tandev<br />Different</h3>
              <div className='cta mt-4'><Button text="Find Out More" style={6} width='fit-content' type="button" /></div>
            </div>
            <div className='text col-md-7 col-lg-8'>
              <div>At TanDev, we prioritize people and companies first. We understand that businesses have needs that might fall outside of their necessary realm or scope and don’t have the time or energy for it to be done quickly and succinctly. </div>
              <div className='graph mt-4'>
                <div>
                  <div className='heading mb-2'>Proficiency</div>
                  <progress className='progress' value="100" max="100"></progress>
                </div>
                <div>
                  <div className='heading mb-2'>Experience</div>
                  <progress className='progress' value="100" max="100"></progress>
                </div>
              </div>
            </div>
          </div>
          <Video id="824939718" width="100%" height='550px' />
        </div>
      </div>
      <div className="carousel mt-5">
        <Chip text='Case Studies' style={1} />
        <h1 className="text-center mb-5">View Our Recent Completed<br />Projects</h1>
        <div className='carousel-container'>
          {projects.map((project) => (
            <div className="carousel-card" key={project.id} onClick={() => rotateProjectsBack()}>
              <img src={project.imgPath} />
              <div className="overlay" >
                <div className="title">{project.title}</div>
                <div className="body">{project.text}</div>
              </div>
            </div>
          ))}
          <div className='svg' onClick={() => rotateProjects()}>
            <Svg name="arrow" width="45px" height="45px" margin="auto"/>
          </div>
          <div className='svg' onClick={() => rotateProjectsBack()}>
            <Svg name="arrow" width="45px" height="45px" margin="auto"/>
          </div>
        </div>
      </div>
      <div className='container'>
        <div className='homepage-technologies my-5'>
          <h1 className='text-center'>Technologies We Use</h1>
          <div className='technologies-overflow mt-5'>
            <div className='technologies-container'>
              <div className='technologies-card'>
                <div className='row'>
                  <div className='image col-md-8'>
                    <Svg name="drupal_svg" width="41px" height="41px" margin="10px" />
                  </div>
                  <div className='years'>8+</div>
                </div>
                <div className='heading'>Drupal</div>
                <div className='text'>Worked on multiple large site projects using Drupal 7-11.</div>
              </div>
              <div className='technologies-card'>
                <div className='row'>
                  <div className='image col-md-8'>
                    <Svg name="JavaScript" width="41px" height="41px" margin="10px" />
                  </div>
                  <div className='years'>13+</div>
                </div>
                <div className='heading'>JavaScript</div>
                <div className='text'>Exposure to Typescript, React, VueJS, EmberJS, and NodeJS.</div>
              </div>
              <div className='technologies-card'>
                <div className='row'>
                  <div className='image col-md-8'>
                    <Svg name="php" width="41px" height="41px" margin="10px" />
                  </div>
                  <div className='years'>13+</div>
                </div>
                <div className='heading'>PHP</div>
                <div className='text'>Used while working with multiple CMS's including: WordPress, SilverStripe, Joomla, and Symphony.</div>
              </div>
              <div className='technologies-card'>
                <div className='row'>
                  <div className='image col-md-8'>
                    <Svg name="html" width="41px" height="41px" margin="10px" />
                  </div>
                  <div className='years'>15+</div>
                </div>
                <div className='heading'>HTML 5</div>
                <div className='text'>Utilized in multiple professional projects.</div>
              </div>
              <div className='technologies-card'>
                <div className='row'>
                  <div className='image col-md-8'>
                    <Svg name="css3" width="41px" height="41px" margin="10px" />
                  </div>
                  <div className='years'>15+</div>
                </div>
                <div className='heading'>CSS 3</div>
                <div className='text'>Experienced using preprocesses such as SASS/SCSS, Stylus, and Less.</div>
              </div>
              <div className='technologies-card'>
                <div className='row'>
                  <div className='image col-md-8'>
                    <Svg name="mysql" width="41px" height="41px" margin="10px" />
                  </div>
                  <div className='years'>12+</div>
                </div>
                <div className='heading'>MySQL</div>
                <div className='text'>Utilized in multiple professional projects.</div>
              </div>
              <div className='technologies-card'>
                <div className='row'>
                  <div className='image col-md-8'>
                    <Svg name="devops" width="41px" height="41px" margin="10px" />
                  </div>
                  <div className='years'>12+</div>
                </div>
                <div className='heading'>DevOps</div>
                <div className='text'>AWS (6 years), Apache (12 years), Docker (4 years).</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='homepage-plans my-5'>
        <div className='team-building-plan p-5'>
          <div className='heading'>Access diverse and specialized teams to build your business.</div>
          <div className='team-building-plan-cards my-5'>
            <div className='team-building-plan-card px-3 py-4'>
              <Svg name="monitor" width="48px" height="48px" margin="0" />
              <div className='heading mt-3'>Drupal Development</div>
              <div className='text my-5'>With over three decades of hands-on expertise, our seasoned professionals deliver architect-level solutions tailored to your unique needs and challenges.</div>
              <div className='cta'><Button className='text-dark' text="Hire Now" style={6} width='fit-content' type="button" /></div>
            </div>
            <div className='team-building-plan-card px-3 py-4'>
              <Svg name="globe" width="48px" height="48px" margin="0" />
              <div className='heading mt-3'>WordPress Development</div>
              <div className='text my-5'>From front-end to full-stack, web developers at Devsinc build and maintain sites that are efficient and structured to perform according to your business requirements.</div>
              <div className='cta'><Button className='text-dark' text="Hire Now" style={6} width='fit-content' type="button" /></div>
            </div>
            <div className='team-building-plan-card px-3 py-4'>
              <Svg name="bug" width="48px" height="48px" margin="0" />
              <div className='heading mt-3'>Project Management</div>
              <div className='text my-5'>The quality assurance team works on test plans, executing test cases, identifying and documenting bugs, and more to deliver high-quality, reliable software.</div>
              <div className='cta'><Button className='text-dark' text="Hire Now" style={6} width='fit-content' type="button" /></div>
            </div>
          </div>
        </div>
        <div className='fixed-plan'>
          <div className='fixed-plan-card m-5'>
            <div className='heading'>Try product development on pre-defined terms.</div>
            <div className='text my-5'>In this plan, we offer a fixed number of experts for a pre-decided timeframe. Additionally, this plan comprises a few requirements, a fixed schedule, and a definite cost.</div>
            <div className='cta'><Button text="Explore Details" style={6} width='fit-content' type="button" /></div>
          </div>
        </div>
      </div>
      <div className='container team mb-5'>
        <div className='homepage-our-team homepage-indent'>
          <div className='heading col-md-10'>
            <h1 className='col-md-5'>Together We Thrive</h1>
            <div className='col-md-5'>
              <div>Our team is small, yet mighty, and we are always looking for other talented <span>Drupal Devs</span> to join our pool!</div>
              <div className='cta mt-4'><Button text="Join Our Team" style={6} width='fit-content' type="button" /></div>
            </div>
          </div>
          <div className='content mt-5'>
            <Headshot name="michael" fullName="Michael Robbe" title="Founder / Senior Drupal Architect" linkedin="https://www.linkedin.com/in/michaelrobbe/" />
            <Headshot name="ben" fullName="Ben Witzke" title="Co-Founder / Business Operations" linkedin="https://www.linkedin.com/in/benjaminwitzke/" />
            <Headshot name="bargav" fullName="Bargav Kondapu" title="Senior Drupal Developer" linkedin="https://www.linkedin.com/in/bhar1red/" />
            <Headshot name="max" fullName="Maxwell Bos" title="Developer" linkedin="https://www.linkedin.com/in/maxwell-bos-162a9112b/" />
            <Headshot name="andrew" fullName="Andrew Wilson" title="Developer" linkedin="https://www.linkedin.com/in/andrewwilsonjs/" />
          </div>
        </div>
      </div>
    </div>
  )
}
